function extractQueryHeaders(headers: Record<string, string>): QueryHeader[] {
  const queryHeaders: QueryHeader[] = []

  for (const [key, value] of Object.entries(headers)) {
    if (key.startsWith("x-query-")) {
      const paramName = key.slice(8) // Remove 'x-query-' part
      const queryHeader: QueryHeader = {
        key: paramName,
        value,
      }

      queryHeaders.push(queryHeader)
    }
  }

  return queryHeaders
}

export function useQueryHeaders(headers: Record<string, string>) {
  const queryHeaders = useState<QueryHeader[]>("queryHeaders", () => [])

  if (!queryHeaders.value.length)
    queryHeaders.value = extractQueryHeaders(headers)

  return queryHeaders
}
